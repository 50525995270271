<template>
  <div>
    <v-snackbar
      timeout="2200"
      v-model="visible"
      bottom
      :color="color"
      outlined
      right
      text
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    color: "error",
    text: null,
    visible: false
  }),
  created() {
    const self = this;

    this.$events.$on("snackEvent", (message, type = 'error') => {
      self.visible = true;
      self.text = message;
      self.color = type;
    });
  },
};
</script>