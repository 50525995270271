<template>
  <v-dialog v-model="visible" width="800">
    <v-card class="pa-4" :loading="disabled">
      <v-card-title class="pa-0">
        Редактирование заказа
      </v-card-title>
      <v-divider class="my-4"></v-divider>
      <v-card-title class="pa-0 subtitle-1"> Выбранные товары </v-card-title>
      <div class="products">
        <ul>
          <li v-for="item in form.products" :key="item.id">
            <div class="products-prod-image">
              <v-img class="image" :src="item.img"></v-img>
            </div>
            <div class="products-prod_name">{{ item.name }}</div>
            <div class="products-prod-count">
              <v-text-field :value="item.quantity">
                <v-icon @click="item.quantity += 1" slot="append" color="green">
                  mdi-plus
                </v-icon>
                <v-icon
                  @click="item.quantity -= 1"
                  slot="prepend"
                  color="green"
                >
                  mdi-minus
                </v-icon>
              </v-text-field>
            </div>

            <div class="products-prod-remove">
              <v-btn @click="prodRemove(item)" fab dark small color="red">
                <v-icon dark> mdi-trash-can-outline </v-icon>
              </v-btn>
            </div>
          </li>
          <li @click="prodDialog()" class="products-prod-add">Добавить</li>
        </ul>
      </div>
      <v-divider class="my-4"></v-divider>
      <v-form :disabled="disabled" ref="form" v-model="valid">
        <v-text-field
          v-model="form.custFirstName"
          label="Имя плательщика"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.custLastName"
          label="Фамилия плательщика"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.custEmail"
          label="E-mail плательщика"
          :rules="emailRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.custPhone"
          :rules="phoneRules"
          label="Телефон плательщика"
          required
        ></v-text-field>
        <v-card-title class="pa-0"> Данные получателя </v-card-title>
        <v-text-field
          v-model="form.shippFirstName"
          label="Имя получателя"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.shippLastName"
          label="Фамилия получателя"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.shippPhone"
          label="Телефон получателя"
          :rules="phoneRules"
          required
        ></v-text-field>
        <v-select
          v-model="form.cityId"
          :items="cityList"
          :rules="[(v) => !!v || 'Город не выбран']"
          item-text="ru_name_city"
          item-value="id"
          label="Город"
          required
        ></v-select>
        <v-text-field
          v-model="form.street"
          :rules="fieldRules"
          label="Улица"
          required
        ></v-text-field>
        <v-checkbox v-model="form.flStreet">
          <template v-slot:label>
            <div>
              - уточнить удобный адрес доставки по телефону у получателя.
            </div>
          </template>
        </v-checkbox>
        <v-textarea
          outlined
          v-model="form.shippAdress"
          :rules="fieldRules"
          label="Адрес"
          value="This is clearable text."
        ></v-textarea>
        <v-select
          v-model="form.country"
          :items="countryList"
          item-text="country"
          item-value="id"
          label="Страна"
        ></v-select>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="form.date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.date"
              label="Дата доставки"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.date"
            scrollable
            first-day-of-week="1"
            locale="ru"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-select
          v-model="form.deliveryPeriod"
          :items="deliveryPeriodList"
          :rules="[(v) => !!v || 'Период не выбран']"
          item-text="name"
          item-value="id"
          label="Период доставки"
        ></v-select>
        <v-textarea
          outlined
          v-model="form.custComment"
          :rules="fieldRules"
          label="Коментарии пользователя"
          value=""
        ></v-textarea>
        <v-btn class="mr-4" :loading="disabled" @click="submit">
          Отправить
        </v-btn>
      </v-form>
    </v-card>

    <editOrderSelectProd @retProducts="getProducts($event)" />
  </v-dialog>
</template>

<script>
import editOrderSelectProd from "./editOrderSelectProd.vue";

export default {
  components: { editOrderSelectProd },
  data: () => ({
    menu: false,
    modal: false,
    visible: false,
    disabled: false,
    deliveryPeriodList: [
      { id: 1, name: "Доставка с 00:00 до 02:00", val: "с 00:00 до 02:00" },
      { id: 2, name: "Доставка с 02:00 до 04:00", val: "с 02:00 до 04:00" },
      { id: 3, name: "Доставка с 04:00 до 06:00", val: "с 04:00 до 06:00" },
      { id: 4, name: "Доставка с 06:00 до 08:00", val: "с 06:00 до 08:00" },
      { id: 5, name: "Доставка с 08:00 до 10:00", val: "с 08:00 до 10:00" },
      { id: 6, name: "Доставка с 10:00 до 12:00", val: "с 10:00 до 12:00" },
      { id: 7, name: "Доставка с 12:00 до 14:00", val: "с 12:00 до 14:00" },
      { id: 8, name: "Доставка с 14:00 до 16:00", val: "с 14:00 до 16:00" },
      { id: 9, name: "Доставка с 16:00 до 18:00", val: "с 16:00 до 18:00" },
      { id: 10, name: "Доставка с 18:00 до 20:00", val: "с 18:00 до 20:00" },
      { id: 11, name: "Доставка с 20:00 до 22:00", val: "с 20:00 до 22:00" },
      { id: 12, name: "Доставка с 22:00 до 24:00", val: "с 22:00 до 24:00" },
      {
        id: 13,
        name: "Точное время доставки (+10$)",
        val: "Точное время доставки (+10$)",
      },
    ],
    countryList: [],
    cityList: [],
    form: {
      orderId: null,
      date: new Date().toISOString().substr(0, 10),
      deliveryPeriod: null,
      cityId: null,
      country: null,
      custFirstName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      shippFirstName: null,
      shippLastName: null,
      shippPhone: null,
      flStreet: 0,
      custComment: null,
      shippAdress: null,
      street: null,
      products: [],
    },
    fieldRules: [
      (v) => !!v || "Поле не может быть пустым",
      (v) => (v && v.length >= 3) || "Field must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail не может быть пустым",
      (v) => /.+@.+\..+/.test(v) || "E-mail не вилиден",
    ],
    phoneRules: [
      (v) => !!v || "Телефон не может быть пустым",
      (v) =>
        /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(v) ||
        "Телефон не вилиден",
    ],
    valid: true,
  }),
  methods: {
    async getCityList() {
      try {
        const response = await this.$axios.get("/citys");

        this.cityList = response.data.response;
      } catch (e) {
        console.error(e);
      }
    },

    async getCountryList() {
      try {
        const response = await this.$axios.get("/countries");

        this.countryList = response.data.response;
        this.form.country = 21;
      } catch (e) {
        console.error(e);
      }
    },

    async getOrderInfo(orderId) {
      this.disabled = true;

      await this.$axios
        .get(`/order?id=${orderId}`)
        .then((response) => {
          const resp = response.data.response;

          this.form.orderId = orderId;
          this.form.custFirstName = resp.customer_firstname;
          this.form.custLastName = resp.customer_lastname;
          this.form.custEmail = resp.customer_email;
          this.form.custPhone = resp.billing_telephone;
          this.form.shippFirstName = resp.shipping_firstname;
          this.form.shippLastName = resp.shipping_lastname;
          this.form.shippPhone = resp.shipping_telephone;
          this.form.cityId = resp.shipping_city_id;
          this.form.shippAdress = resp.shipping_address;
          this.form.street = resp.street;
          this.form.flStreet = resp.fl_street;
          this.form.custComment = resp.customers_comment;
          this.form.country = resp.shipping_country_id;
          this.form.date = resp.date_ship;
          this.form.products = resp.products;
          this.form.deliveryPeriod = resp.shipTimeType;

          this.disabled = false;
        })
        .catch((error) => {
          console.log(error);

          this.disabled = false;
        });
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.disabled = true;

        let city = this.cityList.find((item) => item.id === this.form.cityId);

        let delivPeriod = this.deliveryPeriodList.find(
          (item) => item.id === this.form.deliveryPeriod
        );
        let country = this.countryList.find(
          (item) => item.id === this.form.country
        );

        await this.$axios
          .put("/order", {
            orderId: this.form.orderId,
            custFirstName: this.form.custFirstName,
            custLastName: this.form.custLastName,
            custEmail: this.form.custEmail,
            custPhone: this.form.custPhone,
            shippFirstName: this.form.shippFirstName,
            shippLastName: this.form.shippLastName,
            shippPhone: this.form.shippPhone,
            cityId: city.id,
            city: city.ru_name_city,
            street: this.form.street,
            flStreet: this.form.flStreet,
            shippAdress: this.form.shippAdress,
            country: country.country,
            countryId: country.id,
            custComment: this.form.custComment,
            dateShip: this.form.date,
            delivPeriod: delivPeriod.val,
            products: JSON.stringify(this.form.products),
          })
          .then(() => {
            this.disabled = false;

            this.$events.$emit(
              "snackEvent",
              `Заказ успешно обновлён - №${this.form.orderId}`,
              "green"
            );
            this.$emit("refreshForm", this.form.orderId);
          })
          .catch((error) => {
            console.log(error);
            this.disabled = false;
          });
      }
    },

    async prodDialog() {
      this.$events.$emit("SHOW_EDIT_ORDER_SELECT_PROD_DIALOG");

      this.$events.$emit("prodEditUpdate", this.form.products);
    },

    async getProducts(products) {
      this.form.products = products;
    },

    async prodRemove(item) {
      item.inProd = false;
      item.quantity = 1;

      const products = this.form.products;

      let index = products.indexOf(item);
      products.splice(index, 1);

      this.$events.$emit("prodEditUpdate", this.form.products);
    },
  },
  created() {
    this.$events.$on("SHOW_EDIT_ORDER_DIALOG", (orderId) => {
      this.visible = true;

      this.getOrderInfo(orderId);
      this.getCityList();
      this.getCountryList();
    });
  },
};
</script>

<style lang="scss" scoped>
.products {
  ul {
    padding: 0;
  }

  li {
    display: flex;
    background-color: #e7e7e7;
    padding: 8px;
    height: 100%;
    width: 100%;
    align-items: center;
    margin: 5px 0;

    .products-prod_name {
      display: inline-block;
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 4%;
      width: 100%;
    }

    .products-prod-image {
      display: inline-block;
      width: 70px;
    }

    .products-prod-count {
      display: flex;
      margin-right: 3%;
      width: 40%;
      justify-content: end;
    }

    .products-prod-remove {
      display: flex;
      width: 100%;
      justify-content: end;
    }
  }
}

.products-prod-add {
  display: flex;
  text-transform: uppercase;
  font-size: 18px;
  justify-content: center;
  padding: 1em !important;
  background-color: transparent !important;
  border: 1px solid;
  border-style: dashed;
  margin-top: 1.1em !important;
  cursor: pointer;
}
</style>