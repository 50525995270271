import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    auth: {
      login: null,
      role: null,
      token: null,
      twoFa: null
    },
    ordCounts: 20
  },
  getters: {
    getToken (state) {
      return state.auth.token;
    },
    getLogin (state) {
      return state.auth.login;
    },
    getRole (state) {
      return state.auth.role;
    },
    getCounts(state) {
      return state.ordCounts;
    }
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.auth = payload;
    },
    LOGOUT_AUTH(state) {
      state.auth.login = null;
      state.auth.role = null;
      state.auth.token = null;
      state.auth.twoFa = null;
    },
    SET_ORD_COUNTS(state, payload) {
      state.ordCounts = payload;
    }
  },
})
