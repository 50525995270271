<template>
  <v-dialog v-model="visible" width="800">
    <v-card class="pa-4">
      <v-card-title class="pa-0">
        Создание новой заявки на доставку заказа
      </v-card-title>
      <v-divider class="my-4"></v-divider>
      <v-card-title class="pa-0 subtitle-1"> Выбранные товары </v-card-title>
      <div class="products">
        <ul>
          <li v-for="item in form.products" :key="item.id">
            <div class="products-prod-image">
              <v-img class="image" :src="item.img"></v-img>
            </div>
            <div class="products-prod_name">{{ item.name }}</div>
            <div class="products-prod-count">
              <v-text-field :value="item.quantity">
                <v-icon @click="item.quantity += 1" slot="append" color="green">
                  mdi-plus
                </v-icon>
                <v-icon
                  @click="item.quantity -= 1"
                  slot="prepend"
                  color="green"
                >
                  mdi-minus
                </v-icon>
              </v-text-field>
            </div>

            <div class="products-prod-remove">
              <v-btn @click="prodRemove(item)" fab dark small color="red">
                <v-icon dark> mdi-trash-can-outline </v-icon>
              </v-btn>
            </div>
          </li>
          <li @click="prodDialog()" class="products-prod-add">Добавить</li>
        </ul>
      </div>
      <v-divider class="my-4"></v-divider>
      <v-form :disabled="disabled" ref="form" v-model="valid">
        <v-text-field
          v-model="form.custFirstName"
          label="Имя плательщика"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.custLastName"
          label="Фамилия плательщика"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.custEmail"
          label="E-mail плательщика"
          :rules="emailRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.custPhone"
          :rules="phoneRules"
          label="Телефон плательщика"
          required
        ></v-text-field>
        <v-card-title class="pa-0"> Данные получателя </v-card-title>
        <v-text-field
          v-model="form.shippFirstName"
          label="Имя получателя"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.shippLastName"
          label="Фамилия получателя"
          :rules="fieldRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.shippPhone"
          label="Телефон получателя"
          :rules="phoneRules"
          required
        ></v-text-field>
        <v-select
          v-model="form.cityId"
          :items="cityList"
          :rules="[(v) => !!v || 'Город не выбран']"
          item-text="ru_name_city"
          item-value="id"
          label="Город"
          required
        ></v-select>
        <v-text-field
          v-model="form.street"
          :rules="fieldRules"
          label="Улица"
          required
        ></v-text-field>
        <v-checkbox v-model="form.flStreet">
          <template v-slot:label>
            <div>
              - уточнить удобный адрес доставки по телефону у получателя.
            </div>
          </template>
        </v-checkbox>
        <v-textarea
          outlined
          v-model="form.shippAdress"
          :rules="fieldRules"
          label="Адрес"
          value="This is clearable text."
        ></v-textarea>
        <v-select
          v-model="form.country"
          :items="countryList"
          item-text="country"
          item-value="id"
          label="Страна"
        ></v-select>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="form.date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.date"
              label="Дата доставки"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.date"
            scrollable
            first-day-of-week="1"
            locale="ru"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-select
          v-model="form.deliveryPeriod"
          :items="deliveryPeriodList"
          :rules="[(v) => !!v || 'Период не выбран']"
          item-text="name"
          item-value="id"
          label="Период доставки"
        ></v-select>
        <v-textarea
          outlined
          v-model="form.custComment"
          :rules="fieldRules"
          label="Коментарии пользователя"
          value=""
        ></v-textarea>
        <v-select
          v-model="form.payType"
          :items="payTypeList"
          :rules="[(v) => !!v || 'Тип оплаты не выбран']"
          item-text="name"
          item-value="id"
          label="Тип оплаты"
        ></v-select>
        <v-btn class="mr-4" :loading="disabled" @click="submit"> Отправить </v-btn>
      </v-form>
    </v-card>

    <createOrderSelectProd @retProducts="getProducts($event)" />
  </v-dialog>
</template>

<script>
import createOrderSelectProd from "./createOrderSelectProd.vue";

export default {
  components: { createOrderSelectProd },
  data: () => ({
    menu: false,
    modal: false,
    visible: false,
    disabled: false,
    deliveryPeriodList: [
      { id: 1, name: "Доставка с 00:00 до 02:00", val: "с 00:00 до 02:00" },
      { id: 2, name: "Доставка с 02:00 до 04:00", val: "с 02:00 до 04:00" },
      { id: 3, name: "Доставка с 04:00 до 06:00", val: "с 04:00 до 06:00" },
      { id: 4, name: "Доставка с 06:00 до 08:00", val: "с 06:00 до 08:00" },
      { id: 5, name: "Доставка с 08:00 до 10:00", val: "с 08:00 до 10:00" },
      { id: 6, name: "Доставка с 10:00 до 12:00", val: "с 10:00 до 12:00" },
      { id: 7, name: "Доставка с 12:00 до 14:00", val: "с 12:00 до 14:00" },
      { id: 8, name: "Доставка с 14:00 до 16:00", val: "с 14:00 до 16:00" },
      { id: 9, name: "Доставка с 16:00 до 18:00", val: "с 16:00 до 18:00" },
      { id: 10, name: "Доставка с 18:00 до 20:00", val: "с 18:00 до 20:00" },
      { id: 11, name: "Доставка с 20:00 до 22:00", val: "с 20:00 до 22:00" },
      { id: 12, name: "Доставка с 22:00 до 24:00", val: "с 22:00 до 24:00" },
      {
        id: 13,
        name: "Точное время доставки (+10$)",
        val: "Точное время доставки (+10$)",
      },
    ],
    payTypeList: [
      { id: 4, name: "Visa, MasterCard, PayPal" },
      { id: 1, name: "Наличные" },
      { id: 7, name: "Яндекс.Деньги" },
      { id: 6, name: "WebMoney" },
      { id: 19, name: "Сбербанке РФ(по квитанции)" },
      { id: 11, name: "Терминал IBox(Для Украины)" },
      { id: 9, name: "Иное" },
      { id: 3, name: "Банковский перевод" },
    ],
    countryList: [],
    cityList: [],
    form: {
      date: new Date().toISOString().substr(0, 10),
      payType: null,
      deliveryPeriod: null,
      cityId: null,
      country: null,
      custFirstName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      shippFirstName: null,
      shippLastName: null,
      shippPhone: null,
      flStreet: 0,
      custComment: null,
      shippAdress: null,
      street: null,
      products: [],
    },
    fieldRules: [
      (v) => !!v || "Поле не может быть пустым",
      (v) => (v && v.length >= 3) || "Field must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail не может быть пустым",
      (v) => /.+@.+\..+/.test(v) || "E-mail не вилиден",
    ],
    phoneRules: [
      (v) => !!v || "Телефон не может быть пустым",
      (v) =>
        /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(v) ||
        "Телефон не вилиден",
    ],
    valid: true,
  }),
  methods: {
    async getCityList() {
      try {
        const response = await this.$axios.get("/citys");

        this.cityList = response.data.response;
      } catch (e) {
        console.error(e);
      }
    },

    async getCountryList() {
      try {
        const response = await this.$axios.get("/countries");

        this.countryList = response.data.response;
        this.form.country = 21;
      } catch (e) {
        console.error(e);
      }
    },

    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.disabled = true;

          let city = this.cityList.find((item) => item.id === this.form.cityId);
          let payment = this.payTypeList.find(
            (item) => item.id === this.form.payType
          );
          let delivPeriod = this.deliveryPeriodList.find(
            (item) => item.id === this.form.deliveryPeriod
          );
          let country = this.countryList.find(
            (item) => item.id === this.form.country
          );

          await this.$axios.post("/order", {
            custFirstName: this.form.custFirstName,
            custLastName: this.form.custLastName,
            custEmail: this.form.custEmail,
            custPhone: this.form.custPhone,
            shippFirstName: this.form.shippFirstName,
            shippLastName: this.form.shippLastName,
            shippPhone: this.form.shippPhone,
            cityId: city.id,
            city: city.ru_name_city,
            street: this.form.street,
            flStreet: this.form.flStreet,
            shippAdress: this.form.shippAdress,
            country: country.country,
            countryId: country.id,
            custComment: this.form.custComment,
            paymentType: payment.name,
            paymentId: payment.id,
            dateShip: this.form.date,
            delivPeriod: delivPeriod.val,
            products: JSON.stringify(this.form.products),
          }).then((response) => {
            const orderId = response.data.response.orderId;
            
            this.visible = false;
            
            this.$events.$emit("snackEvent", `Заказ успешно создан - №${orderId}`, "green");
            this.$emit('orderCreated');
          }).catch((error) => {
            this.$events.$emit("snackEvent", 'Не удалось создать заказ');
            console.log(error);
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },

    async prodDialog() {
      this.$events.$emit("SHOW_CREATE_ORDER_SELECT_PROD_DIALOG");
    },

    async getProducts(products) {
      this.form.products = products;
    },

    async prodRemove(item) {
      item.inProd = false;
      item.quantity = 1;

      const products = this.form.products;

      let index = products.indexOf(item);
      products.splice(index, 1);

      this.$events.$emit("prodUpdate", this.form.products);
    },
  },
  created() {
    this.$events.$on("SHOW_CREATE_ORDER_DIALOG", () => {
      this.visible = true;

      this.getCityList();
      this.getCountryList();
    });
  },
};
</script>

<style lang="scss" scoped>
.products {
  ul {
    padding: 0;
  }

  li {
    display: flex;
    background-color: #e7e7e7;
    padding: 8px;
    height: 100%;
    width: 100%;
    align-items: center;
    margin: 5px 0;

    .products-prod_name {
      display: inline-block;
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 4%;
      width: 100%;
    }

    .products-prod-image {
      display: inline-block;
      width: 70px;
    }

    .products-prod-count {
      display: flex;
      margin-right: 3%;
      width: 40%;
      justify-content: end;
    }

    .products-prod-remove {
      display: flex;
      width: 100%;
      justify-content: end;
    }
  }
}

.products-prod-add {
  display: flex;
  text-transform: uppercase;
  font-size: 18px;
  justify-content: center;
  padding: 1em !important;
  background-color: transparent !important;
  border: 1px solid;
  border-style: dashed;
  margin-top: 1.1em !important;
  cursor: pointer;
}
</style>