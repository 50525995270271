<template>
  <div id="app">
    <v-app>
      <snackbar />
      <appbar />
      <v-main class="grey lighten-3">
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script lang="ts">
import snackbar from "./components/snackbar.vue";
import appbar from "./components/appbar.vue";

export default {
  components: {
    snackbar,
    appbar
  },
  async created() {
    if (!this.$store.getters.getToken) return;
    this.$axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$store.getters.getToken}`;
  },
};
</script>