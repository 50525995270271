<template>
  <v-app-bar v-model="visible" app color="white"  v-if="login">
    <v-tabs>
      <div class="user ml-4">
        <v-avatar class="elevation-5" color="info">
          <v-icon icon="mdi-account-circle"></v-icon>
        </v-avatar>
        <div class="text-subtitle-1 ml-4">
          {{ login }}
          <p class="text-caption grey--text lighten-1 ma-0">
            {{ translateRole(role) }}
          </p>
        </div>
      </div>

      <div class="d-flex" style="width: 100%">
        <v-tab class="d-flex" disabled> Каталог </v-tab>
      <v-tab to="/orders"> Список заказов </v-tab>
      <v-tab disabled> Настройки </v-tab>
      <v-tab disabled> Модули </v-tab>
      <v-tab disabled> Рассылка </v-tab>
      <v-tab disabled> Отчеты </v-tab>
      <v-tab disabled> Роли </v-tab>
      </div>

      <div class="exit">
        <v-btn icon small color="primary" @click="logout">
          <v-icon> mdi-export </v-icon>
        </v-btn>
      </div>
    </v-tabs>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    visible: true,
  }),
  methods: {
    logout() {
      this.$store.commit("LOGOUT_AUTH");
      this.$router.push("/login");
    },
  },
  computed: {
    login() {
      return this.$store.getters.getLogin;
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  margin-right: 2em;
  align-items: center;
}

.exit {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-right: 1em;
}
</style>