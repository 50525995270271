<template>
  <v-dialog v-model="visible" width="800">
    <v-card class="pa-4">
      <v-card-title class="pa-0"> Выберите товары </v-card-title>
      <div>
        <v-text-field
          v-model="search"
          label="Поиск товара"
          prepend-icon="mdi-magnify"
          @keyup="searchProduct(search)"
        ></v-text-field>
      </div>

      <v-virtual-scroll :items="productList" :item-height="90" height="700">
        <template v-slot:default="{ item }">
          <v-list-item class="products">
            <v-list-item-avatar rounded size="65">
              <v-img class="image" :src="item.img"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="d-flex">
              <div class="products-prod_name">{{ item.name }}</div>
              <div class="products-prod-count">
                <v-text-field :value="item.quantity">
                  <v-icon
                    @click="item.quantity += 1"
                    slot="append"
                    color="green"
                  >
                    mdi-plus
                  </v-icon>
                  <v-icon
                    @click="item.quantity -= 1"
                    slot="prepend"
                    color="green"
                  >
                    mdi-minus
                  </v-icon>
                </v-text-field>
              </div>
            </v-list-item-content>

            <v-list-item-action class="products-prod-remove">
              <v-btn
                v-if="item.inProd == false"
                @click="prodAdd(item)"
                fab
                dark
                small
                color="green"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>

              <v-btn
                v-if="item.inProd"
                @click="prodRemove(item)"
                fab
                dark
                small
                color="green"
              >
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    productList: [],
    products: [],
    search: "",
  }),
  methods: {
    async prodAdd(item) {
      this.products.push(item);

      this.$emit("retProducts", this.products);
      item.inProd = true;
    },

    async prodRemove(item) {
      const index = this.products.indexOf(item);

      this.products.splice(index, 1);
      item.inProd = false;
    },

    async getProductList() {
      try {
        const response = await this.$axios.get("/products");

        this.productList = response.data.response;
      } catch (e) {
        console.error(e);
      }
    },

    async searchProduct(name) {
      try {
        const response = await this.$axios.get(`/products?name=${name}`);

        this.productList = response.data.response;
      } catch (e) {
        console.error(e);
      }
    },
  },
  created() {
    this.$events.$on("SHOW_EDIT_ORDER_SELECT_PROD_DIALOG", () => {
      this.visible = true;
    });

    this.$events.$on("prodEditUpdate", (payload) => {
      this.products = payload;

      this.products.forEach( el => {
          let index = this.productList.findIndex(prod => prod.id === el.id);

          if (index != null) {
              let prod = this.productList.[index];

              prod.inProd = true;
          }
      });
    });

    this.getProductList();
  },
};
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  background-color: #e7e7e7;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 0 7px;
  flex: unset;

  .products-prod_name {
    text-transform: uppercase;
  }

  .products-prod-count {
    width: 88px;
  }

  .products-prod-remove {
    display: flex;
    width: 7%;
    justify-content: end;
  }
}

.products-prod-add {
  display: flex;
  text-transform: uppercase;
  font-size: 18px;
  justify-content: center;
  padding: 1em !important;
  background-color: transparent !important;
  border: 1px solid;
  border-style: dashed;
  margin-top: 1.1em !important;
  cursor: pointer;
}

.v-list-item__content {
  padding: 0 !important;
  flex: unset;
  width: 100%;
  justify-content: space-between;
}

.v-list-item__content > * {
  flex: unset !important;
}
</style>