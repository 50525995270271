<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card elevation="12">
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-toolbar color="light-blue" dark flat>
                <v-toolbar-title>Авторизация</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model="login"
                    label="Логин"
                    name="email"
                    :rules="reqRules"
                    prepend-icon="mdi-account"
                    type="text"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    prepend-icon="mdi-lock"
                    name="pass"
                    label="Пароль"
                    :rules="reqRules"
                    type="password"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  outlined
                  :loading="loading"
                  color="light-blue"
                  @click="checkAuth()"
                  >Авторизация</v-btn
                >
              </v-card-actions>
            </v-window-item>

            <v-window-item :value="2">
              <v-toolbar color="light-blue" dark flat>
                <v-toolbar-title
                  >Введите код 2FA аутентификации</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model="a2fa"
                    label="Код двухфакторной аутентификации"
                    name="email"
                    :rules="reqRules"
                    prepend-icon="mdi-qrcode"
                    type="text"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  outlined
                  :loading="loading"
                  color="blue"
                  @click="check2fa()"
                  >Ок</v-btn
                >
              </v-card-actions>
            </v-window-item>
          </v-window>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    login: null,
    password: null,
    loading: false,
    error: false,
    reqRules: [(v) => !!v || "Поле не может быть пустым!"],
    passwordVisible: false,
    step: 1,
    auth: null,
    a2fa: null,
  }),
  methods: {
    async checkAuth() {
      if (!this.$refs.form.validate()) return false;

      const data = { login: this.login, password: this.password };
      this.loading = true;

      try {
        const response = await this.$axios.post("/auth", data);

        if (response.status == 200) {
          if (
            response.data.response.login != null &&
            response.data.response.role !== "user"
          ) {
            if (response.data.response.twoFa === true) {
              this.auth = response.data.response;
              this.step = 2;
            } else {
              this.auth = response.data.response;
              this.$store.commit("SET_AUTH", this.auth);

              if (!this.$store.getters.getToken) return;
              this.$axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.$store.getters.getToken}`;

              this.$events.$emit("snackEvent", "Авторизация успешна", "green");
              this.$router.push("/orders");
              
              console.log(this);
            }
          }
        } else {
          this.$events.$emit("snackEvent", "Неверные данные");
        }
      } catch (e) {
        this.$events.$emit("snackEvent", "Сервер не отвечает");
      } finally {
        this.loading = false;
      }
    },

    async check2fa() {
      try {
        const response = await this.$axios.get("/check2fa", {
          params: {
            code: this.a2fa,
            user: this.auth.login,
          },
        });

        if (response.status == 200) {
          if (response.data.auth == true) {
            this.auth.token = response.data.token;

            this.$store.commit("SET_AUTH", this.auth);

            if (!this.$store.getters.getToken) return;
            this.$axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.$store.getters.getToken}`;

            this.$events.$emit("snackEvent", "Авторизация успешна", "green");
            this.$router.push("/orders");
          } else {
            console.error("Неверный код двухфакторной аутентификации");
            document.location.reload();
          }
        } else {
          console.error("Ошибка сервера");
          document.location.reload();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>