export default {
    methods: {
        translatePayStatus(status) {
            switch (status) {
                case 'no_mark': return 'Не оплачен';
                case 'not_payment': return 'Не оплачен';
                case 'partially_paid': return 'Частично оплачен';
                case 'paid': return 'Оплачен';
            }
        },
        translateOrdStatus(status) {
            switch (status) {
                case 'no_mark' : return 'Нет статуса';
                case 'not_deliver' : return 'Отложить доставку';
                case 'manufactured' : return 'Изготавливается';
                case 'ready' : return 'Готов';
                case 'delivered' : return 'Доставляется';
                case 'deliver' : return 'Доставлен';
            }
        },
        
        statusColor(status) {
            switch (status) {
                case 'no_mark' : return 'grey darken-1';
                case 'not_deliver' : return 'red darken-1';
                case 'manufactured' : return 'blue-grey lighten-2';
                case 'ready' : return 'light-blue accent-3';
                case 'delivered' : return 'deep-orange lighten-1';
                case 'deliver' : return 'green accent-4';
            }
        }
    }
}
