<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Комментарий к заказу - №{{ order }}
        </v-card-title>

        <v-card-text class="body-2 mt-4 black--text">
            {{ text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    text: null,
    order: null
  }),
  created() {
    this.$events.$on("SHOW_ORDER_DESCRIPTION_DIALOG", (order, text) => {
        this.dialog = true;
        this.text = text;
        this.order = order;
    });
  },
};
</script>