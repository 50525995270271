<template>
  <div class="mt-3 pa-7">
    <v-row>
      <v-col class="col-6">
        <div class="page-title">
          <h1 class="font-weight-light">Список заказов</h1>
        </div>
      </v-col>
      
      <v-col class="col-6 d-flex justify-end align-center">
        <v-btn v-if="role === 'admin'" class="ma-2" outlined color="green" @click="creatOder">
          Создать заказ
        </v-btn>
      </v-col>

      <v-col class="col-12 col-lg-12 col-md-12 col-sm-6">
        <div class="sort_title text-subtitle-1 mb-3">Быстрая сортировка:</div>
        <div class="fast-sort d-sm-flex text-uppercase text-center">
          <div class="sort mr-11">
            Вчера
            <div class="deliv mt-4">
              <v-btn
                @click="fastSort('yesterday', 'by_deliv')"
                :loading="loaderDay.yesterday"
                depressed
                block
                outlined
                color="primary"
              >
                Доставка
              </v-btn>
            </div>
            <div v-if="role != 'florist'" class="get mt-2">
              <v-btn
                @click="fastSort('yesterday', 'by_make')"
                :loading="loaderDay.yesterday"
                depressed
                block
                outlined
                color="primary"
              >
                Поступившие
              </v-btn>
            </div>
          </div>
          <div class="sort mr-11">
            Сегодня
            <div class="deliv mt-4">
              <v-btn
                @click="fastSort('today', 'by_deliv')"
                :loading="loaderDay.today"
                depressed
                block
                outlined
                color="primary"
              >
                Доставка
              </v-btn>
            </div>
            <div v-if="role != 'florist'" class="get mt-2">
              <v-btn
                @click="fastSort('today', 'by_make')"
                :loading="loaderDay.today"
                depressed
                block
                outlined
                color="primary"
              >
                Поступившие
              </v-btn>
            </div>
          </div>
          <div class="sort mr-11">
            Завтра
            <div class="deliv mt-4">
              <v-btn
                @click="fastSort('tomorrow', 'by_deliv')"
                :loading="loaderDay.tomorrow"
                depressed
                block
                outlined
                color="primary"
              >
                Доставка
              </v-btn>
            </div>
          </div>

          <div v-if="role === 'florist'" class="sort sort-florist mr-11">
            После-завтра
            <div class="deliv mt-4">
              <v-btn
                @click="fastSort('tomorrow1', 'by_deliv')"
                :loading="loaderDay.tomorrow1"
                depressed
                block
                outlined
                color="primary"
              >
                Доставка
              </v-btn>
            </div>
          </div>
          <div v-if="role === 'florist'" class="sort sort-florist mr-11">
            После-после-завтра
            <div class="deliv mt-4">
              <v-btn
                @click="fastSort('tomorrow2', 'by_deliv')"
                :loading="loaderDay.tomorrow2"
                depressed
                block
                outlined
                color="primary"
              >
                Доставка
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>

      <v-col v-if="role === 'admin' || role === 'dispatcher' || role === 'content_menager'" class="col-12">
        <div class="ord_sort">
          <div class="sort_title text-subtitle-1">Сортировать:</div>
          <div class="desc text-caption">
            Укажите один, либо несколько параметры
          </div>
          <v-row>
            <v-col class="col-lg-2 col-md-2 col-sm-2 col-6">
              <v-text-field v-model="orderId" label="№ заказа"></v-text-field>
            </v-col>
            <v-col class="col-lg-2 col-md-3 col-sm-3 col-6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Дата"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  range
                  first-day-of-week="1"
                  locale="ru"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="col-lg-2 col-md-2 col-sm-2 col-6">
              <v-select
                v-model="sortCity"
                :items="cityList"
                item-text="ru_name_city"
                item-value="id"
                label="Город"
              ></v-select>
            </v-col>
            <v-col class="col-lg-2 col-md-3 col-sm-3 col-6">
              <v-select
                v-model="defTypeSelect"
                :items="typeSelectList"
                item-text="type"
                item-value="val"
                menu-props="val"
                label="По дате"
                @change="changeTypSelect"
              ></v-select>
            </v-col>
            <v-col
              class="col-lg-1 col-md-2 col-sm-1 col-12 d-flex align-center"
            >
              <v-btn
                outlined
                color="blue"
                @click="getOrdersSort()"
                :loading="sortBtnLoading"
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col class="col-lg-11 col-7 d-flex justify-start align-center">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.maxpage"
          :total-visible="7"
          circle
          @input="movePage"
          v-if="pagination.maxpage != 0"
          class="mr-4"
        ></v-pagination>
        <div style="width: 80px">
          <v-select
            v-model="orderCounts"
            :items="orderCountsList"
            @change="changeOrdCounts"
            hint="Количество"
            persistent-hint
          ></v-select>
        </div>
      </v-col>

      <v-col class="col-12 text-subtitle-2">
        Всего заказов: {{ orderCountsAll }}
      </v-col>

      <v-col v-if="role === 'admin' || role === 'dispatcher'">
        <div class="desc text-caption mb-2">
          C отмеченными: (<strong>{{ this.selectedOrd.length }}</strong
          >)
        </div>
        <v-btn
          @click="printSelectedOrder"
          small
          :disabled="selectedOrd.length == 0 ? true : false"
          color="white--text blue-grey"
          >Печать</v-btn
        >
        <v-btn
          @click="deleteSelectedOrder()"
          small
          :disabled="selectedOrd.length == 0 ? true : false"
          color="error"
          class="ml-5"
          >Удалить</v-btn
        >
      </v-col>

      <div class="col-12 pa-0" v-if="!loading">
        <div v-for="(item, index) in orders" :key="index" class="col-12 pa-0">
          <div v-if="item !== null">
            <v-col class="col-12">
              <div :class="statusColor(index)">
                <span class="white--text text-button px-4">
                  {{ translateOrdStatus(index) }}
                </span>
              </div>
            </v-col>
            <v-col class="col-12">
              <order
                v-if="role === 'admin' || role === 'dispatcher' || role === 'content_menager'"
                :order="orders[index]"
                @retCheckedId="getSelectedOrd($event)"
              />
              <orderFlorist
                v-else-if="role === 'florist'"
                :order="orders[index]"
                @retCheckedId="getSelectedOrd($event)"
              />
            </v-col>
          </div>
        </div>
      </div>

      <v-col class="col-12" v-if="loading">
        <v-skeleton-loader class="mx-auto my-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto my-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto my-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto my-4" type="card"></v-skeleton-loader>
      </v-col>

      <v-col class="col-lg-11 col-7 d-flex justify-start align-center">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.maxpage"
          :total-visible="7"
          circle
          @input="movePage"
          v-if="pagination.maxpage != 0"
          class="mr-4"
        ></v-pagination>
      </v-col>
    </v-row>

    <createOrder @orderCreated="getOrders()" />
    <orderDescDialog />
    <payMessage />
    <orderInfo v-if="role === 'admin' || role === 'dispatcher' || role === 'content_menager'" @orderRemove="getOrders()" />
    <orderInfoFlorist v-if="role === 'florist'"/>
    <eventDialog />
  </div>
</template>

<script>
import createOrder from "../components/order/createOrder.vue";
import orderDescDialog from "../components/order/description.vue";
import payMessage from "../components/order/payMessage.vue";
import eventDialog from "../components/order/eventDialog.vue";

import orderInfo from "../components/order/orderInfo.vue";
import orderInfoFlorist from "../components/order/orderInfoFlorist.vue";

import order from "../components/order/order.vue";
import orderFlorist from "../components/order/orderFlorist.vue";

export default {
  components: { createOrder, orderDescDialog, payMessage, orderInfo, order, eventDialog, orderFlorist, orderInfoFlorist },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    typeSelectList: [
      { type: "подачи заказ", val: "by_make" },
      { type: "доставки заказ", val: "by_deliv" },
    ],
    defTypeSelect: { type: "доставки заказ", val: "by_deliv" },
    typeSelect: "by_deliv",
    orderId: null,
    orders: [],
    cityList: [],
    sortCity: 0,
    loading: true,
    sortBtnLoading: false,
    pagination: {
      page: 1,
      maxpage: 0,
      where: null,
    },
    orderCountsList: [20, 50, 100, 200, 500],
    orderCounts: 20,
    orderCountsAll: null,
    loadAssist: true,
    loaderDay: {
      yesterday: false,
      today: false,
      tomorrow: false,
      tomorrow1: false,
      tomorrow2: false
    },
    selectedOrd: []
  }),
  methods: {
    async getOrders() {
      try {
        let paramsList = {};

        paramsList.date = this.date;
        paramsList.dateType = this.typeSelect;
        paramsList.count = this.orderCounts;

        const response = await this.$axios.get("/orders", {
          params: paramsList,
        });

        this.orders = response.data.response.orders;
        this.orderCountsAll = response.data.response.counts;
        this.pagination.page = response.data.response.page;
        this.pagination.maxpage = response.data.response.maxPage;
        this.pagination.where = response.data.response.where;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getCityList() {
      try {
        const response = await this.$axios.get("/citys");

        this.cityList = response.data.response;
        this.cityList.unshift({ id: 0, ru_name_city: "ЛЮБОЙ ГОРОД" });
      } catch (e) {
        console.error(e);
      }
    },

    async getOrdersSort() {
      try {
        this.loading = true;
        this.sortBtnLoading = true;
        this.selectedOrd = [];

        let paramsList = {};

        if (this.orderId !== null) paramsList.order = this.orderId;

        if (typeof this.date == "string") {
          paramsList.date = this.date;
        } else if (typeof this.date == "object") {
          if (this.date.length == 1) {
            paramsList.date = this.date[0];
          } else {
            paramsList.date = `${this.date[0]}, ${this.date[1]}`;
          }
        }

        if (this.sortCity !== null) paramsList.city = this.sortCity;

        paramsList.dateType = this.typeSelect;
        paramsList.count = this.orderCounts;

        const response = await this.$axios.get("/orders", {
          params: paramsList,
        });

        this.orders = response.data.response.orders;
        this.orderCountsAll = response.data.response.counts;
        this.pagination.page = response.data.response.page;
        this.pagination.maxpage = response.data.response.maxPage;
        this.pagination.where = response.data.response.where;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.sortBtnLoading = false;
      }
    },

    async changeTypSelect(val) {
      this.typeSelect = val;
    },

    async movePage(newPage) {
        this.loading = true;
        this.selectedOrd = [];

      try {
        const response = await this.$axios.get(
          `${this.pagination.where}&page=${newPage}&count=${this.orderCounts}`
        );

        this.orders = response.data.response.orders;
        this.pagination.page = response.data.response.page;
        this.pagination.maxpage = response.data.response.maxPage;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async changeOrdCounts(count) {
      this.$store.commit("SET_ORD_COUNTS", count);
      this.getOrdersSort();
    },

    async creatOder() {
      this.$events.$emit("SHOW_CREATE_ORDER_DIALOG");
    },

    async fastSort(day, type) {
      try {
        this.loaderDay.[day] = true;
        this.selectedOrd = [];
        
        let date;
        
        if (day == 'yesterday') {
          date = new Date();
          date = date.setDate(date.getDate()-1);
          date = new Date(date).toISOString().substr(0, 10);
        } else if (day == 'today') {
          date = new Date().toISOString().substr(0, 10);
        } else if (day == 'tomorrow') {
          date = new Date();
          date = date.setDate(date.getDate()+1);
          date = new Date(date).toISOString().substr(0, 10);
        } else if (day == 'tomorrow1') {
          date = new Date();
          date = date.setDate(date.getDate()+2);
          date = new Date(date).toISOString().substr(0, 10);
        } else if (day == 'tomorrow2') {
          date = new Date();
          date = date.setDate(date.getDate()+3);
          date = new Date(date).toISOString().substr(0, 10);
        }

        let paramsList = {
          date: date,
          dateType: type,
          city: 0,
          count: this.orderCounts
        };

        await this.$axios.get("/orders", {
          params: paramsList,
        }).then(response => {
          this.orders = response.data.response.orders;
          this.orderCountsAll = response.data.response.counts;
          this.pagination.page = response.data.response.page;
          this.pagination.maxpage = response.data.response.maxPage;
          this.pagination.where = response.data.response.where;

          this.loaderDay.[day] = false;
        }).catch(e => {
          console.log(e);

          this.loaderDay.[day] = false;
        });
      } catch(e) {
        console.log(e);
        this.loaderDay.[day] = false;
      }
    },

    async getSelectedOrd(orderId) {
      let index = this.selectedOrd.indexOf(orderId);

      if (index !== -1) {
          this.selectedOrd.splice(index, 1)
      } else {
          this.selectedOrd.push(orderId);
      }
    },

    async deleteSelectedOrder() {
        let deleteConfirt =  confirm(
          `Вы действительно хотитe удалить заказы № ${this.selectedOrd}`
        );

        if (deleteConfirt) {
            await this.$axios.delete("/delete", {
                params: {
                    order: JSON.stringify(this.selectedOrd),
                }
            }).then( () => {
                if (this.pagination.where !== null) {
                    this.getOrdersSort();
                } else {
                    this.getOrders();
                }
            }).catch( e => {
                console.log(e);
            });
        }
    },

    async printSelectedOrder() {
      let printLink = JSON.stringify(this.selectedOrd);

      window.open(`https://manage.cvety.by/print?order=${printLink}`, '_blank');
    }
  },
  mounted() {
    this.orderCounts = this.$store.getters.getCounts;

    this.getOrders();
    this.getCityList();
  },
  computed: {
    role() {
      return this.$store.getters.getRole;
    }
  }
};
</script>

<style lang="scss">
.border {
  border-right: 1px solid #e0e0e0;
}

.ord_info {
  width: 100%;
  font-size: 13px;
  font-weight: 600;
}

.action {
  .site {
    font-size: 12px !important;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.title_make {
  width: 100% !important;
  line-height: 1;
}

.adress_ord_info {
  line-height: 1.4;
}

.comm_text {
  max-height: 280px;
  overflow: hidden;
  line-height: 1.2;
}

.prod_img .images {
  max-height: 100px;
  max-width: 100px;
}

.prod_img_two {
  .images {
    max-height: 60px;
    max-width: 60px;
  }
}

.prod_img_thre {
  .images {
    max-height: 50px;
    max-width: 50px;
  }
}

.fast-sort {
}
</style>

<style lang="scss">
@media screen and (max-width: 1500px) {
  .status_action .v-btn__content {
    font-size: 9px;
  }

  .cust_action .v-btn__content {
    font-size: 9px;
  }

  .v-application .text-subtitle-2 {
    font-size: 12px !important;
  }

  .v-application .text-subtitle-1 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1264px) {
  .prod_img .images {
    max-height: 80px !important;
    max-width: 80px !important;
  }

  .border {
    border-bottom: 1px solid #e0e0e0;
  }

  .cust_action .v-btn__content {
    font-size: 0.75rem !important;
  }

  .status_action .v-btn__content {
    font-size: 0.75rem !important;
  }
}

@media screen and (max-width: 960px) {
  .v-application .v-btn__content {
    font-size: 0.54rem !important;
  }

  .sort-florist {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 600px) {
  .page-title {
    line-height: 1;

    h1 {
      font-size: 1.6rem;
    }
  }

  .sort {
    margin-top: 1.2em;
  }
}
</style>