<template>
  <div>
    <v-dialog v-model="visible">
      <v-card v-if="!loading" class="order">
        <v-card-title class="order-title white--text blue-grey lighten-2">
          <v-row>
            <v-col class="col-6">
              <v-icon dark> mdi-basket-outline </v-icon> Заказ №{{
                order.orderID
              }}
            </v-col>
            <v-col class="col-6 d-flex justify-end">
              <v-btn fab small @click="editOrder(order.orderID)" class="mr-4">
                <v-icon dark> mdi-pencil-outline </v-icon>
              </v-btn>
              <v-btn fab small @click="deleteOrder(order.orderID)">
                <v-icon dark> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-row>
            <v-col
              class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
              <div v-if="order.status_payment == 'paid'">
                <v-alert
                  class="green white--text"
                  dense
                  dark
                  border="left"
                  icon="mdi-alert"
                >
                  <strong>Заказ</strong> уже <strong>оплачен</strong>.
                  <strong>Если</strong> это не так, <strong>измените </strong>
                  <strong>статус !</strong>
                </v-alert>
                <div class="actin-buttons">
                  <v-btn
                    @click="
                      changeStatus(order.orderID, 'partially_paid', 'pay')
                    "
                    :loading="loadingPayBtn"
                    dark
                    class="mr-4"
                    color="blue-grey"
                  >
                    Заказ оплачен частично!
                  </v-btn>
                  <v-btn
                    @click="changeStatus(order.orderID, 'not_payment', 'pay')"
                    :loading="loadingPayBtn"
                    color="error"
                  >
                    Убрать пометки
                  </v-btn>
                </div>
              </div>
              <div
                v-else-if="
                  order.status_payment == 'not_payment' ||
                  order.status_payment == 'no_mark'
                "
              >
                <v-alert
                  class="primary"
                  dense
                  dark
                  border="left"
                  icon="mdi-alert"
                >
                  Этот <strong>заказ</strong> еще <strong>не оплачен</strong>.
                  Если <strong>оплата произошла</strong>, выберите
                  <strong>соответствующую</strong> кнопку
                </v-alert>
                <div class="actin-buttons">
                  <v-btn
                    @click="changeStatus(order.orderID, 'paid', 'pay')"
                    :loading="loadingPayBtn"
                    dark
                    class="mr-4"
                    color="blue-grey"
                  >
                    Заказ оплачен!
                  </v-btn>
                  <v-btn
                    @click="
                      changeStatus(order.orderID, 'partially_paid', 'pay')
                    "
                    :loading="loadingPayBtn"
                    dark
                    color="blue-grey"
                  >
                    Заказ оплачен частично!
                  </v-btn>
                </div>
              </div>
              <div v-else-if="order.status_payment == 'partially_paid'">
                <v-alert
                  class="orange white--text"
                  dense
                  dark
                  border="left"
                  icon="mdi-alert"
                >
                  Этот <strong>заказ оплачен частично.</strong> Если
                  <strong>он оплачен полностью</strong>, выберите
                  <strong>соответствующую</strong> кнопку
                </v-alert>
                <div class="actin-buttons">
                  <v-btn
                    @click="changeStatus(order.orderID, 'paid', 'pay')"
                    :loading="loadingPayBtn"
                    dark
                    class="mr-4"
                    color="blue-grey"
                  >
                    Заказ оплачен!
                  </v-btn>
                  <v-btn
                    @click="changeStatus(order.orderID, 'not_payment', 'pay')"
                    :loading="loadingPayBtn"
                    color="error"
                  >
                    Убрать пометки
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
              class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
              <div v-if="role === 'admin'" class="d-flex flex-column align-end">
                <v-btn
                  @click="showPayDialog(order.site, order.orderID)"
                  class="status-btn"
                  dark
                  color="blue-grey"
                >
                  Платежка
                </v-btn>
                <v-btn @click="sendAssist(order.orderID)" :loading="loadAssist" class="status-btn" dark color="blue-grey"> Асист </v-btn>
              </div>
            </v-col>
            <v-col class="col-12 col-xl-4 col-lg-4 order-order">
              <div class="col-title">Заказ</div>
              <div class="order-order_info">
                Номер заказ:
                <span> {{ order.orderID }} </span>
              </div>
              <div class="order-order_info">
                Время подачи:
                <span> {{ order.order_time }} </span>
              </div>
              <div class="order-order_info">
                Покупатель:
                <span>
                  {{ order.customer_firstname }} {{ order.customer_lastname }}
                </span>
              </div>
              <div class="order-order_info">
                E-mail:
                <span> {{ order.customer_email }} </span>
              </div>
              <div class="order-order_info">
                IP-адрес покупателя:
                <span> {{ order.customer_ip }} </span>
              </div>
              <div class="order-order_info">
                Статус:
                <span> Новый </span>
              </div>
              <div class="order-order_info">
                Комментарии:
                <span> {{ order.customers_comment }} </span>
              </div>
            </v-col>
            <v-col class="col-12 col-xl-4 col-lg-4">
              <div class="col-title">Оплата</div>

              <div class="order-order_info">
                Оплата:
                <span> {{ order.payment_type }} </span>
              </div>
              <div class="order-order_info">
                Плательщик:
                <span>
                  {{ order.customer_firstname }} {{ order.customer_lastname }}
                </span>
              </div>
              <div class="order-order_info">
                Тел.:
                <span> {{ order.billing_telephone }} </span>
              </div>
              <div class="order-order_info">
                Адрес плательщика:
                <span> - </span>
              </div>
            </v-col>
            <v-col class="col-12 col-xl-4 col-lg-4">
              <div class="col-title">Доставка</div>

              <div class="order-order_info">
                Доставка:
                <span> - </span>
              </div>
              <div class="order-order_info">
                Получатель:
                <span>
                  {{ order.shipping_firstname }} {{ order.shipping_lastname }}
                </span>
              </div>
              <div class="order-order_info">
                Тел.:
                <span> {{ order.shipping_telephone }} </span>
              </div>
              <div class="order-order_info">
                Адрес доставки заказа:
                <span>
                  {{ order.shipping_address }}, {{ order.shipping_city }},
                  {{ order.shipping_country }}
                </span>
              </div>
              <div class="order-order_info">
                Улица:
                <span> {{ order.street }} </span>
              </div>
              <div class="order-order_info">
                Время доставки:
                <span> {{ order.order_on_time_date }} </span>
              </div>
            </v-col>
            <v-col class="col-12 col-xl-6 col-lg-6 col-xs-6">
              <div class="col-title">Заказанные продукты</div>
              <div>
                <v-simple-table class="prod-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Наименование</th>
                        <th class="text-left">Изображение</th>
                        <th class="text-left">Стоимость</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in order.products"
                        :key="item.orderID"
                        class="font-weight-bold"
                      >
                        <td>{{ item.name }}</td>
                        <td>
                          <v-img
                            max-height="150"
                            max-width="150"
                            :src="item.img_small"
                          ></v-img>
                        </td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
            <v-col
              class="col-12 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12"
            >
              <div class="order-total mt-9">
                Стоимость товаров:
                <span> {{ order.order_amount }} </span>
              </div>
              <div class="order-total">
                Стоимость доставки:
                <span> {{ order.shipping_cost }} </span>
              </div>
              <div class="order-total">
                Скидка, %:
                <span> {{ order.order_discount }} </span>
              </div>
              <div
                class="order-total subtitle-1 black--text font-weight-medium"
              >
                Итого:
                <span> {{ order.totalAmount }} </span>
              </div>
            </v-col>
            <v-col class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="col-title">Статус доставки/изготовления</div>
              <div class="subtitle-1 mb-4">
                Текущий статус -
                <strong class="black--text">
                  {{ translateOrdStatus(order.status_d_m) }}
                </strong>
              </div>
              <div>
                <v-btn
                  :outlined="order.status_d_m == 'no_mark' ? false : true"
                  class="status-btn"
                  color="primary"
                  :loading="loadingStatusBtn"
                  @click="changeStatus(order.orderID, 'no_mark', 'status')"
                >
                  Нет статуса
                </v-btn>
              </div>
              <div>
                <v-btn
                  :outlined="order.status_d_m == 'not_deliver' ? false : true"
                  class="status-btn"
                  color="primary"
                  :loading="loadingStatusBtn"
                  @click="changeStatus(order.orderID, 'not_deliver', 'status')"
                >
                  Отложить доставку
                </v-btn>
              </div>
              <div>
                <v-btn
                  :outlined="order.status_d_m == 'manufactured' ? false : true"
                  class="status-btn"
                  color="primary"
                  :loading="loadingStatusBtn"
                  @click="changeStatus(order.orderID, 'manufactured', 'status')"
                >
                  Изготавливается
                </v-btn>
              </div>
              <div>
                <v-btn
                  :outlined="order.status_d_m == 'ready' ? false : true"
                  class="status-btn"
                  color="primary"
                  :loading="loadingStatusBtn"
                  @click="changeStatus(order.orderID, 'ready', 'status')"
                >
                  Готов
                </v-btn>
              </div>
              <div>
                <v-btn
                  :outlined="order.status_d_m == 'delivered' ? false : true"
                  class="status-btn"
                  color="primary"
                  :loading="loadingStatusBtn"
                  @click="changeStatus(order.orderID, 'delivered', 'status')"
                >
                  Доставляется
                </v-btn>
              </div>
              <div>
                <v-btn
                  :outlined="order.status_d_m == 'deliver' ? false : true"
                  class="status-btn"
                  color="primary"
                  :loading="loadingStatusBtn"
                  @click="changeStatus(order.orderID, 'deliver', 'status')"
                >
                  Доставлен
                </v-btn>
              </div>
            </v-col>
            <v-col class="col-12 col-xl-7">
              <div class="col-title">Выполнение заказа</div>
              <v-simple-table class="prod-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Время</th>
                      <th class="text-left">Статус изготовления доставки</th>
                      <th class="text-left">Статус оплаты</th>
                      <th class="text-left">Автор изменений</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in order.order_status_log"
                      :key="index"
                      class="font-weight-bold"
                    >
                      <td>{{ item.status_change_time }}</td>
                      <td>{{ translateOrdStatus(item.dm_status) }}</td>
                      <td>{{ translatePayStatus(item.payment_status) }}</td>
                      <td>{{ item.user }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-skeleton-loader
          type="card-avatar, article, actions"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </v-card>
    </v-dialog>

    <editOrder @refreshForm="getOrderInfo($event)" />
  </div>
</template>

<script>
import editOrder from "./editOrder.vue";

export default {
  components: { editOrder },
  data: () => ({
    loading: false,
    loadingPayBtn: false,
    loadingStatusBtn: false,
    loadAssist: false,
    visible: false,
    order: [],
  }),
  methods: {
    async getOrderInfo(orderId) {
      this.loading = true;
      this.visible = true;

      await this.$axios
        .get(`/order?id=${orderId}&type=info`)
        .then((response) => {
          this.order = response.data.response;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async editOrder(orderId) {
      this.$events.$emit("SHOW_EDIT_ORDER_DIALOG", orderId);
    },

    async showPayDialog(ordSite, order) {
      let site = ordSite;

      if (site === null) site = "cvety.by";

      this.$events.$emit("SHOW_PAY_MESSAGE", site, order);
    },

    async changeStatus(order, status, type) {
      try {
        if (type === "status") {
          this.loadingStatusBtn = true;

          await this.$axios
            .patch("/status", {
              order: order,
              status: status,
              type: type,
            })
            .then(() => {
              this.order.status_d_m = status;
              this.loadingStatusBtn = false;

              this.getOrderInfo(order);
            })
            .catch((error) => {
              console.log(error);
              this.loadingStatusBtn = false;
            });
        } else if (type === "pay") {
          this.loadingPayBtn = true;

          await this.$axios
            .patch("/status", {
              order: order,
              status: status,
              type: type,
            })
            .then(() => {
              this.order.status_payment = status;
              this.loadingPayBtn = false;

              this.getOrderInfo(order);
            })
            .catch((error) => {
              console.log(error);
              this.loadingPayBtn = false;
            });
        }
      } catch (e) {
        console.error(e);
      }
    },

    async deleteOrder(orderId) {
      try {
        let deleteConfirt = confirm(
          `Вы действительно хотитe удалить заказ №${orderId}`
        );

        if (deleteConfirt) {
          await this.$axios
            .delete("/delete", {
              params: {
                order: orderId,
              },
            })
            .then(() => {
              this.visible = false;
              this.$emit("orderRemove");
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } catch (e) {
        console.error(e);
      }
    },

    async sendAssist(orderId) {
      this.loadAssist = true;

      await this.$axios.delete("/delete", {
        params: {
          order: orderId,
        },
      }).then(() => {
        this.$events.$emit("snackEvent", `Счёт ассит отправлен, по заказу - №${orderId}`, "green");
        this.loadAssist = false;
      }).catch(e => {
        console.log(e);
        this.loadAssist = false;
      });
    },
  },
  created() {
    this.$events.$on("SHOW_ORDER_INFO", (orderId) => {
      this.loading = true;
      this.visible = true;

      this.getOrderInfo(orderId);
    });
  },
  computed: {
    role() {
      return this.$store.getters.getRole;
    }
  }
};
</script>

<style lang="scss">
.order {
  .order-title {
    background-color: #c5c5c5;
  }

  .col-title {
    font-size: 21px;
    font-weight: 600;
    color: #000;
    text-decoration: underline;
    margin-bottom: 20px;
  }

  .order-order_info {
    color: #000;

    span {
      font-weight: 600;
    }
  }

  .prod-table {
    th {
      background-color: #ededed;
    }
  }

  .status-btn {
    width: 220px;
    margin-bottom: 8px;
  }
}
</style>