import { render, staticRenderFns } from "./createOrderSelectProd.vue?vue&type=template&id=60d10ae0&scoped=true&"
import script from "./createOrderSelectProd.vue?vue&type=script&lang=js&"
export * from "./createOrderSelectProd.vue?vue&type=script&lang=js&"
import style0 from "./createOrderSelectProd.vue?vue&type=style&index=0&id=60d10ae0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d10ae0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCard,VCardTitle,VDialog,VDivider,VIcon,VImg,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VTextField,VVirtualScroll})
