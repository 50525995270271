<template>
  <v-dialog v-model="visible" width="800">
    <v-card  :loading="loader">
      <v-card-title class="headline grey lighten-2">
       Напомнить о событии, заказ - №{{ orderId }}
      </v-card-title>

      <form class="pa-4" :disabled="disabled">
        <v-text-field
          v-model="form.subject"
          label="Тема сообщения"
          required
        ></v-text-field>
        <v-textarea
          v-model="form.textMail"
          label="Текст сообщения"
          value="This is clearable text."
        ></v-textarea>
        <v-textarea
          v-model="form.textSms"
          label="Текст SMS"
          value="This is clearable text."
        ></v-textarea>
        <v-btn class="mr-4" @click="submit" :loading="loader">
          Отправить
        </v-btn>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    disabled: true,
    loader: false,
    orderId: null,
    form: {
        subject: null,
        textMail: null,
        textSms: null
    }
  }),
  methods: {
    async submit() {
      this.loader = true;

      await this.$axios
        .get(`/sendevent?orderId=${this.orderId}`)
        .then(() => {
          this.$events.$emit(
            "snackEvent",
            `Наоминание о событии отправлено по заказу - №${this.orderId}`,
            "green"
          );

          this.loader = false;
          this.visible = false;
        })
        .catch((e) => {
          console.log(e);
          this.loader = false;
        });
    },

    async getInfo() {
      this.loader = true;

      try {
        const response = await this.$axios.get(`/event?orderId=${this.orderId}`);

        this.form.textMail = response.data.response.email_template;
        this.form.textSms = response.data.response.sms_template;
        this.form.subject = response.data.response.email_subject;
        this.loader = false;
      } catch (e) {
        console.error(e);
        this.loader = false;
      }
    },
  },
  created() {
    this.$events.$on("SHOW_EVENT_DIALOG", orderId => {
      this.visible = true;
      this.orderId = orderId;

      this.getInfo();
    });
  },
};
</script>