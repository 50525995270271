export default {
    methods: {
        translateRole(role) {
            switch (role) {
                case 'admin': return 'Администратор';
                case 'florist': return 'Флорист';
                case 'dispatcher': return 'Диспетчер';
                case 'content_menager': return 'Менеджер';
            }
        }
    }
}
