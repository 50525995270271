<template>
  <v-dialog v-model="visible" width="800">
    <v-card  :loading="loader">
      <v-card-title class="headline grey lighten-2">
        Платёжное поручение к заказу - №{{ order }}
      </v-card-title>

      <form class="pa-4" :disabled="disabled">
        <v-text-field
          v-model="sender"
          label="Отправитель"
          required
        ></v-text-field>
        <v-text-field
          v-model="subject"
          label="Тема сообщения"
          required
        ></v-text-field>
        <v-textarea
          v-model="text"
          label="Платёжка"
          value="This is clearable text."
        ></v-textarea>
        <v-btn class="mr-4" @click="submit" :loading="loader">
          Отправить
        </v-btn>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    sender: null,
    subject: null,
    text: null,
    ordSite: null,
    order: null,
    disabled: true,
    loader: false,
  }),
  methods: {
    async submit() {
      this.loader = true;

      await this.$axios
        .post(`/sendpaylist`,{
          order: this.order,
          sender: this.sender,
          subject: this.subject,
          text: this.text
        })
        .then(() => {
          this.$events.$emit(
            "snackEvent",
            `Платёжное поручение отправлено по заказу - №${this.order}`,
            "green"
          );

          this.loader = false;
          this.visible = false;
        })
        .catch((e) => {
          console.log(e);
          this.loader = false;

          this.$events.$emit(
            "snackEvent",
            `Не удалось отправить платёжное поручение`,
            "error"
          );
        });
    },

    async getInfo() {
      this.loader = true;

      try {
        const response = await this.$axios.get("/paytemplate", {
          params: {
            site: this.ordSite,
            order: this.order,
          },
        });

        this.sender = response.data.response[0].from_email;
        this.subject = response.data.response[0].subject_email;
        this.text = response.data.response[0].email_template;
        this.disabled = false;
        this.loader = false;
      } catch (e) {
        console.error(e);
        this.loader = false;
      }
    },
  },
  created() {
    this.$events.$on("SHOW_PAY_MESSAGE", (ordSite, order) => {
      this.visible = true;
      this.ordSite = ordSite;
      this.order = order;

      this.getInfo();
    });
  },
};
</script>