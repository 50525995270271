import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Orders from '../views/orders.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      title: 'Список заказов'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path != '/login' && store.getters.getToken == null)
    next({ path: '/login', replace: true });
  else if (to.path == '/login' && store.getters.getToken != null)
    next({ path: '/orders', replace: true });
  else {
    document.title = to.meta.title;
    next();
  }
});

export default router
