<template>
  <div>
    <v-card
      class="pa-3 my-4"
      v-for="(item, index) in order"
      :key="item.orderID"
    >
    <v-lazy v-model="isActive" :options="{
          threshold: .5
        }">
      <v-row>
        <v-col class="col-lg-1 col-md-1 col-sm-2 col-2 border">
          <div
            class="
              action
              d-flex
              flex-column
              align-center
              justify-center
              fill-height
            "
          >
            <div class="d-flex number flex-column align-center justify-center">
              <span
                class="ord_number align-center justify-center text-subtitle-2"
                >#{{ item.index }} </span
              >
              <v-checkbox @click="updateSelected(item.orderID)" class="ml-2"></v-checkbox>
            </div>
            <div class="d-flex site align-center justify-center">
              <span v-if="item.site === null">cvety</span>
              <span v-else-if="item.site === 'ura.by'">ura</span>
              <span v-else-if="item.site === 'belflower.ru'">belf</span>
            </div>
            <div class="ord_actions mt-4 d-flex flex-column">
              <v-btn
                fab
                dark
                small
                @click="orderInfo(item.orderID)"
                color="blue"
              >
                <v-icon dark> mdi-eye </v-icon>
              </v-btn>
              <v-btn
                class="mt-3"
                fab
                dark
                small
                color="primary"
                @click="orderPrint(item.orderID)"
              >
                <v-icon dark> mdi-printer </v-icon>
              </v-btn>
              <v-btn
                class="mt-3"
                fab
                dark
                small
                color="red"
                @click="deleteOrder(index, item.orderID, item.status_d_m)"
              >
                <v-icon dark> mdi-trash-can-outline </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          class="col-lg-2 col-md-3 col-sm-3 col-10 border d-flex flex-column"
        >
          <div class="d-xl-flex">
            <div class="ord_info d-flex justify-start">
              №{{ item.orderID }}
              <span class="pay_type grey--text darken-3 ml-1">
                {{ item.payment_type }}
              </span>
            </div>
            <div
              class="
                ord_price
                my-4 my-lg-0
                d-xl-flex
                justify-lg-end
                font-weight-bold
              "
            >
              <v-icon>mdi-currency-usd</v-icon> {{ item.totalAmount }}
            </div>
          </div>
          <div
            class="pay_status text-overline mt-1 red--text darken-2"
            v-if="item.status_payment == 'no_mark'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_status text-overline mt-1 red--text darken-2"
            v-else-if="item.status_payment == 'not_payment'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_status text-overline mt-1 orange--text darken-4"
            v-else-if="item.status_payment == 'partially_paid'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_status text-overline mt-1 light-blue--text accent-3"
            v-else-if="item.status_payment == 'paid'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_action"
            v-if="
              item.status_payment == 'no_mark' ||
              item.status_payment == 'partially_paid' ||
              item.status_payment == 'not_payment'
            "
          >
            <v-btn
              x-small
              outlined
              color="indigo"
              @click="changeStatus(item.orderID, 'paid', 'pay', index)"
              >оплачен</v-btn
            >
            <v-btn
              x-small
              outlined
              color="indigo"
              class="ml-1"
              @click="
                changeStatus(item.orderID, 'partially_paid', 'pay', index)
              "
              v-if="item.status_payment != 'partially_paid'"
              >частично</v-btn
            >
          </div>
          <div
            class="status mt-4 text-body-2 grey--text lighten-1"
            v-if="item.status_d_m == 'no_mark'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 red--text darken-1"
            v-else-if="item.status_d_m == 'not_deliver'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 blue-grey--text lighten-2"
            v-else-if="item.status_d_m == 'manufactured'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 light-blue--text accent-3"
            v-else-if="item.status_d_m == 'ready'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 deep-orange--text lighten-1"
            v-else-if="item.status_d_m == 'delivered'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 green--text accent-4"
            v-else-if="item.status_d_m == 'deliver'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>

          <div class="status_action px-lg-4">
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'manufactured' ? 'green' : 'indigo'"
              class="mt-2"
              @click="
                changeStatus(item.orderID, 'manufactured', 'status', index)
              "
            >
              Изготавливается
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'ready' ? 'green' : 'indigo'"
              class="mt-2"
              @click="changeStatus(item.orderID, 'ready', 'status', index)"
            >
              Готов
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'not_deliver' ? 'green' : 'indigo'"
              class="mt-2"
              @click="
                changeStatus(item.orderID, 'not_deliver', 'status', index)
              "
            >
              Отложить доставку
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'delivered' ? 'green' : 'indigo'"
              class="mt-2"
              @click="changeStatus(item.orderID, 'delivered', 'status', index)"
            >
              Доставляется
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'deliver' ? 'green' : 'indigo'"
              class="mt-2"
              @click="changeStatus(item.orderID, 'deliver', 'status', index)"
            >
              Доставлен
            </v-btn>
          </div>
        </v-col>
        <v-col class="col-lg-1 col-md-1 col-3 d-md-block d-sm-none border">
          <div class="title_make d-flex grey--text lighten-4 text-caption">
            Дата создания заказа
          </div>
          <div
            class="
              date_make
              d-flex
              fill-height
              align-center
              justify-center
              text-center text-subtitle-1
              mt-0
            "
          >
            {{ item.order_time }}
          </div>
        </v-col>
        <v-col class="col-lg-2 col-md-3 col-9 col-sm-2 border">
          <div class="title_make d-flex grey--text lighten-4 text-caption">
            Время доставки / Информация о клиенте
          </div>
          <div
            class="
              adress_ord_info
              d-flex
              flex-column
              justify-center
              text-subtitle-2
              fill-height
            "
          >
            <span class="time"> {{ item.order_on_time_date }} </span>
            <span class="adress mt-2">
              <b>{{ item.shipping_city }}</b
              ><br />
              {{ item.street }}<br />
              {{ item.shipping_address }}<br />
            </span>
            <span class="customer mt-2">
              {{ item.shipping_firstname }}
            </span>
            <span class="phone mt-2">
              {{ item.shipping_telephone }}
            </span>
          </div>
        </v-col>
        <v-col
          class="
            col-lg-1 col-md-2 col-sm-2
            border
            d-sm-flex
            flex-column
            justify-center
          "
        >
          <div class="prod_name">
            <div class="name text-subtitle-2 mb-1">
              <b v-if="item.products.length > 0">{{ item.products[0].name }}</b>
            </div>
          </div>
          <div
            class="prod_img d-flex justify-center"
            v-if="item.products.length > 0"
          >
            <v-img class="images" :src="item.products[0].img_small">
              <v-overlay
                absolute
                opacity="0.3"
                v-if="item.products[0].Quantity > 1"
              >
                <div class="prod_count text-h6">
                  {{ item.products[0].Quantity }} шт.
                </div>
              </v-overlay>
            </v-img>
          </div>
          <div
            class="prod_img_two d-flex justify-center mt-1"
            v-if="item.products.length > 1"
          >
            <v-img class="images" :src="item.products[1].img_small">
              <v-overlay
                absolute
                opacity="0.3"
                v-if="item.products[1].Quantity > 1"
              >
                <div class="prod_count text-h6">
                  {{ item.products[1].Quantity }} шт.
                </div>
              </v-overlay>
            </v-img>
          </div>
          <div
            class="prod_img_thre d-flex justify-center mt-1"
            v-if="item.products.length > 2"
          >
            <v-img class="images" :src="item.products[2].img_small">
              <v-overlay
                absolute
                opacity="0.3"
                v-if="item.products[2].Quantity > 1"
              >
                <div class="prod_count text-h6">
                  {{ item.products[2].Quantity }} шт.
                </div>
              </v-overlay>
            </v-img>
          </div>
        </v-col>
        <v-col
          class="col-lg-1 col-12 col-md-2 col-sm-3 border d-flex flex-column"
        >
          <div class="title_make grey--text lighten-4 text-caption">
            Покупатель
          </div>
          <div
            class="
              cust_info
              d-flex
              flex-column
              justify-center
              fill-height
              text-body-2
            "
          >
            <div class="cust_name">{{ item.customer_firstname }}</div>
            <div class="cust_phone">{{ item.billing_telephone }}</div>
            <div v-if="role === 'admin'" class="cust_action mt-4">
              <v-btn
                small
                block
                outlined
                color="indigo"
                class="mt-2"
                @click="showPayDialog(item.site, item.orderID)"
              >
                платежка
              </v-btn>
              <v-btn
                @click="sendAssist(item.orderID)"
                :loading="loadAssist"
                small
                block
                outlined
                color="indigo"
                class="mt-2"
              >
                ассист
              </v-btn>
              <v-btn
                v-if="item.SubscribeEvent !== 0 && item.SubscribeEvent !== null"
                @click="showEventDialog(item.orderID)"
                :loading="loadAssist"
                small
                block
                outlined
                color="indigo"
                class="mt-2"
              >
                событие
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          class="
            col-lg-2 col-12
            d-md-none d-sm-none
            border
            d-lg-flex d-flex
            flex-column
          "
        >
          <div class="title_make grey--text lighten-4 text-caption">
            Комментарий
          </div>
          <div
            class="
              comm_text
              d-flex
              flex-column
              justify-center
              fill-height
              text-body-2
            "
          >
            {{ item.customers_comment }}
          </div>
           <v-btn
              small
              depressed
              class="mt-1"
              v-if="item.customers_comment != ' '"
              @click="showComment(item.orderID, item.customers_comment)"
              >Читать</v-btn
            >
        </v-col>
        <v-col class="col-lg-2 col-12 d-md-none d-sm-none border d-lg-block">
          <div class="title_make grey--text lighten-4 text-caption">
            По какому поводу поздравляли
          </div>

          <div class="d-flex flex-column fill-height justify-center">
            <div class="status_action">
              <v-btn
                small
                outlined
                block
                :color="item.SubscribeEvent == 1 ? 'green' : 'indigo'"
                class="mt-2"
                @click="setSubsType(item.orderID, '1', index)"
              >
                день рождения
              </v-btn>
              <v-btn
                small
                outlined
                block
                :color="item.SubscribeEvent == 2 ? 'green' : 'indigo'"
                class="mt-2"
                @click="setSubsType(item.orderID, '2', index)"
              >
                день свадьбы
              </v-btn>
              <v-btn
                small
                outlined
                block
                :color="item.SubscribeEvent == 3 ? 'green' : 'indigo'"
                class="mt-2"
                @click="setSubsType(item.orderID, '3', index)"
              >
                с благодарностью
              </v-btn>
              <v-btn
                small
                outlined
                block
                :color="item.SubscribeEvent == 4 ? 'green' : 'indigo'"
                class="mt-2"
                @click="setSubsType(item.orderID, '4', index)"
              >
                годовщина отношений
              </v-btn>
              <v-btn
                small
                outlined
                block
                :color="item.SubscribeEvent == 5 ? 'green' : 'indigo'"
                class="mt-2"
                @click="setSubsType(item.orderID, '5', index)"
              >
                юбилей
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-lazy>
    </v-card>
  </div>
</template>

<script>
export default {
  props: { order: Array },
  data: () => ({
      loadAssist: false,
      isActive: false
  }),
  methods: {
    async showComment(order, text) {
      this.$events.$emit("SHOW_ORDER_DESCRIPTION_DIALOG", order, text);
    },

    async showPayDialog(ordSite, order) {
      let site = ordSite;

      if (site === null) site = "cvety.by";

      this.$events.$emit("SHOW_PAY_MESSAGE", site, order);
    },

    async sendAssist(orderId) {
      this.loadAssist = true;

      await this.$axios.get(`/sendassist?order=${orderId}`).then(() => {
        this.$events.$emit("snackEvent", `Счёт ассит отправлен, по заказу - №${orderId}`, "green");
        this.loadAssist = false;
      }).catch(e => {
        console.log(e);
        this.loadAssist = false;

        this.$events.$emit("snackEvent", `Не удалось отправить - счёт ассит`, "error");
      });
    },

    async changeStatus(order, status, type, index) {
      try {
        if (type === "status") {
          await this.$axios.patch("/status", {
            order: order,
            status: status,
            type: type,
          });

          this.order[index].status_d_m = status;
        } else if (type === "pay") {
          await this.$axios.patch("/status", {
            order: order,
            status: status,
            type: type,
          });

          this.order[index].status_payment = status;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async deleteOrder(index, order) {
    try {
        let deleteConfirt = confirm(
          `Вы действительно хотитe удалить заказ №${order}`
        );

        if (deleteConfirt) {
          await this.$axios.delete("/delete", {
            params: {
              order: order,
            },
          });

        this.order.splice(index, 1);
         
        }
      } catch (e) {
        console.error(e);
      }
    },

    async setSubsType(order, status, index) {
      try {
        await this.$axios.patch("/substype", {
          order: order,
          type: status,
        });

         this.order[index].SubscribeEvent = status;
      } catch (e) {
        console.error(e);
      }
    },

    async orderInfo(orderId) {
      this.$events.$emit('SHOW_ORDER_INFO', orderId);
    },

    async showEventDialog(orderId) {
      this.$events.$emit('SHOW_EVENT_DIALOG', orderId);
    },

    async updateSelected(orderId) {
      this.$emit('retCheckedId', orderId);
    },

    async orderPrint(orderId) {
      window.open(`https://manage.cvety.by/print?order=${orderId}`, '_blank');
    }
  },
  mounted() {

  },
  computed: {
    role() {
      return this.$store.getters.getRole;
    }
  }
};
</script>