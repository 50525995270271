<template>
  <div>
    <v-card
      class="pa-3 my-4"
      v-for="(item, index) in order"
      :key="item.orderID"
    >
    <v-lazy v-model="isActive" :options="{
          threshold: .5
        }">
      <v-row>
        <v-col class="col-lg-1 col-md-1 col-sm-2 col-2 border">
          <div
            class="
              action
              d-flex
              flex-column
              align-center
              justify-center
              fill-height
            "
          >
            <div class="d-flex number flex-column align-center justify-center">
              <span
                class="ord_number align-center justify-center text-subtitle-2"
                >#{{ item.index }} </span
              >
            </div>
            <div class="ord_actions mt-4 d-flex flex-column">
              <v-btn
                fab
                dark
                small
                @click="orderInfo(item.orderID)"
                color="blue"
              >
                <v-icon dark> mdi-eye </v-icon>
              </v-btn>
              <v-btn
                class="mt-3"
                fab
                dark
                small
                color="primary"
                @click="orderPrint(item.orderID)"
              >
                <v-icon dark> mdi-printer </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          class="col-lg-2 col-md-3 col-sm-4 col-10 border d-flex flex-column"
        >
          <div class="d-xl-flex">
            <div class="ord_info d-flex justify-start">
              №{{ item.orderID }}
            </div>
          </div>
          <div
            class="pay_status text-overline mt-1 red--text darken-2"
            v-if="item.status_payment == 'no_mark'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_status text-overline mt-1 red--text darken-2"
            v-else-if="item.status_payment == 'not_payment'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_status text-overline mt-1 orange--text darken-4"
            v-else-if="item.status_payment == 'partially_paid'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="pay_status text-overline mt-1 light-blue--text accent-3"
            v-else-if="item.status_payment == 'paid'"
          >
            {{ translatePayStatus(item.status_payment) }}
          </div>
          <div
            class="status mt-4 text-body-2 grey--text lighten-1"
            v-if="item.status_d_m == 'no_mark'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 red--text darken-1"
            v-else-if="item.status_d_m == 'not_deliver'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 blue-grey--text lighten-2"
            v-else-if="item.status_d_m == 'manufactured'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 light-blue--text accent-3"
            v-else-if="item.status_d_m == 'ready'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 deep-orange--text lighten-1"
            v-else-if="item.status_d_m == 'delivered'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>
          <div
            class="status mt-4 text-body-2 green--text accent-4"
            v-else-if="item.status_d_m == 'deliver'"
          >
            <b>{{ translateOrdStatus(item.status_d_m) }}</b>
          </div>

          <div class="status_action px-lg-4">
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'manufactured' ? 'green' : 'indigo'"
              class="mt-2"
              @click="
                changeStatus(item.orderID, 'manufactured', 'status', index)
              "
            >
              Изготавливается
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'ready' ? 'green' : 'indigo'"
              class="mt-2"
              @click="changeStatus(item.orderID, 'ready', 'status', index)"
            >
              Готов
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'not_deliver' ? 'green' : 'indigo'"
              class="mt-2"
              @click="
                changeStatus(item.orderID, 'not_deliver', 'status', index)
              "
            >
              Отложить доставку
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'delivered' ? 'green' : 'indigo'"
              class="mt-2"
              @click="changeStatus(item.orderID, 'delivered', 'status', index)"
            >
              Доставляется
            </v-btn>
            <v-btn
              small
              outlined
              block
              :color="item.status_d_m == 'deliver' ? 'green' : 'indigo'"
              class="mt-2"
              @click="changeStatus(item.orderID, 'deliver', 'status', index)"
            >
              Доставлен
            </v-btn>
          </div>
        </v-col>
        <v-col class="col-lg-1 col-md-2 col-3 d-md-block d-sm-none border">
          <div class="title_make d-flex grey--text lighten-4 text-caption">
            Дата создания заказа
          </div>
          <div
            class="
              date_make
              d-flex
              fill-height
              align-center
              justify-center
              text-center text-subtitle-1
              mt-0
            "
          >
            {{ item.order_time }}
          </div>
        </v-col>
        <v-col class="col-lg-3 col-md-2 col-9 col-sm-4 border">
          <div class="title_make d-flex grey--text lighten-4 text-caption">
            Время доставки / Информация о получателе
          </div>
          <div
            class="
              adress_ord_info
              d-flex
              flex-column
              justify-center
              text-subtitle-2
              fill-height
            "
          >
            <span class="time"> {{ item.order_on_time_date }} </span>
            <span class="adress mt-2">
              <b>{{ item.shipping_city }}</b
              ><br />
              {{ item.street }}<br />
              {{ item.shipping_address }}<br />
            </span>
            <span class="customer mt-2">
              {{ item.shipping_firstname }}
            </span>
            <span class="phone mt-2">
              {{ item.shipping_telephone }}
            </span>
          </div>
        </v-col>
        <v-col
          class="
            col-lg-2 col-md-2 col-sm-2
            border
            d-sm-flex
            flex-column
            justify-center
          "
        >
          <div class="prod_name">
            <div class="name text-subtitle-2 mb-1">
              <b v-if="item.products.length > 0">{{ item.products[0].name }}</b>
            </div>
          </div>
          <div
            class="prod_img d-flex justify-center"
            v-if="item.products.length > 0"
          >
            <v-img class="images" :src="item.products[0].img_small">
              <v-overlay
                absolute
                opacity="0.3"
                v-if="item.products[0].Quantity > 1"
              >
                <div class="prod_count text-h6">
                  {{ item.products[0].Quantity }} шт.
                </div>
              </v-overlay>
            </v-img>
          </div>
          <div
            class="prod_img_two d-flex justify-center mt-1"
            v-if="item.products.length > 1"
          >
            <v-img class="images" :src="item.products[1].img_small">
              <v-overlay
                absolute
                opacity="0.3"
                v-if="item.products[1].Quantity > 1"
              >
                <div class="prod_count text-h6">
                  {{ item.products[1].Quantity }} шт.
                </div>
              </v-overlay>
            </v-img>
          </div>
          <div
            class="prod_img_thre d-flex justify-center mt-1"
            v-if="item.products.length > 2"
          >
            <v-img class="images" :src="item.products[2].img_small">
              <v-overlay
                absolute
                opacity="0.3"
                v-if="item.products[2].Quantity > 1"
              >
                <div class="prod_count text-h6">
                  {{ item.products[2].Quantity }} шт.
                </div>
              </v-overlay>
            </v-img>
          </div>
        </v-col>
        <v-col
          class="
            col-lg-3 col-12
            col-md-2 d-xs-none
            border
            d-lg-flex d-flex
            flex-column
          "
        >
          <div class="title_make grey--text lighten-4 text-caption">
            Текст на открытку
          </div>
          <div
            class="
              comm_text
              d-flex
              flex-column
              justify-center
              fill-height
              text-body-2
            "
          >
            {{ item.customers_comment }}
          </div>
           <v-btn
              small
              depressed
              class="mt-1"
              v-if="item.customers_comment != ' '"
              @click="showComment(item.orderID, item.customers_comment)"
              >Читать</v-btn
            >
        </v-col>
      </v-row>
    </v-lazy>
    </v-card>
  </div>
</template>

<script>
export default {
  props: { order: Array },
  data: () => ({
      loadAssist: false,
      isActive: false
  }),
  methods: {
    async showComment(order, text) {
      this.$events.$emit("SHOW_ORDER_DESCRIPTION_DIALOG", order, text);
    },

    async showPayDialog(ordSite, order) {
      let site = ordSite;

      if (site === null) site = "cvety.by";

      this.$events.$emit("SHOW_PAY_MESSAGE", site, order);
    },

    async sendAssist(orderId) {
      this.loadAssist = true;

      await this.$axios.get(`/sendassist?order=${orderId}`).then(() => {
        this.$events.$emit("snackEvent", `Счёт ассит отправлен, по заказу - №${orderId}`, "green");
        this.loadAssist = false;
      }).catch(e => {
        console.log(e);
        this.loadAssist = false;
      });
    },

    async changeStatus(order, status, type, index) {
      try {
        if (type === "status") {
          await this.$axios.patch("/status", {
            order: order,
            status: status,
            type: type,
          });

          this.order[index].status_d_m = status;
        } else if (type === "pay") {
          await this.$axios.patch("/status", {
            order: order,
            status: status,
            type: type,
          });

          this.order[index].status_payment = status;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async deleteOrder(index, order) {
    try {
        let deleteConfirt = confirm(
          `Вы действительно хотитe удалить заказ №${order}`
        );

        if (deleteConfirt) {
          await this.$axios.delete("/delete", {
            params: {
              order: order,
            },
          });

        this.order.splice(index, 1);
         
        }
      } catch (e) {
        console.error(e);
      }
    },

    async setSubsType(order, status, index) {
      try {
        await this.$axios.patch("/substype", {
          order: order,
          type: status,
        });

         this.order[index].SubscribeEvent = status;
      } catch (e) {
        console.error(e);
      }
    },

    async orderInfo(orderId) {
      this.$events.$emit('SHOW_ORDER_INFO', orderId);
    },

    async showEventDialog(orderId) {
      this.$events.$emit('SHOW_EVENT_DIALOG', orderId);
    },

    async updateSelected(orderId) {
      this.$emit('retCheckedId', orderId);
    },

    async orderPrint(orderId) {
      window.open(`http://127.0.0.1:8000/print?order=${orderId}`, '_blank');
    }
  },
  mounted() {},
};
</script>